// @flow

import { graphql, useStaticQuery } from "gatsby";

import Helmet from "react-helmet";
import React from "react";
import { globalHistory } from "@reach/router";

function _buildStructData(extraStructData: Object, orgStructData: Object): Object {
  let structData = {};

  if (extraStructData) {
    for (let key of Object.keys(extraStructData)) {
      if (key === "@type" && extraStructData[key] === "Organization") {
        structData = {...structData, ...orgStructData};
      } else if (!Array.isArray(extraStructData[key])
        && typeof extraStructData[key] === "object") {
        structData = {...structData, [key]: {
            ..._buildStructData(extraStructData[key], orgStructData)
          }
        };
      } else {
        structData = {...structData, [key]: extraStructData[key]}
      }
    }
  }

  return structData;
}

function HtmlHead(props: Object) {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const {location} = globalHistory;
  const meta = props.meta || {};
  const {description, title} = props;
  const pathname = location && location.pathname;
  const url = process.env.GATSBY_CLIENT_URL + pathname;
  const logoUrl = `${process.env.GATSBY_CLIENT_URL}/favicon.ico`;

  const orgStructData = {
    "@type": "Organization",
    "name": "bukitesveiki.lt",
    "url": process.env.GATSBY_CLIENT_URL,
    "sameAs": ["https://www.facebook.com/bukitesveikilt-316389365211216"],
    "logo": {
      "@type": "ImageObject",
      "url": logoUrl
    }
  };

  const builtStructData = _buildStructData(props.extraStructData, orgStructData);
  const structData = Object.keys(builtStructData).length > 0 ? {
    "@context": "http://schema.org",
    ...builtStructData
  } : {}

  return (
    <Helmet
      htmlAttributes={{
        lang: 'lt',
      }}
      script={[{
        "type": "application/ld+json",
        "innerHTML": `${JSON.stringify(structData)}`
      }]}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: logoUrl
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: 'robots',
          content: process.env.GATSBY_ENV !== 'PROD' ? 'noindex,nofollow' : (meta.robots || 'index,follow')
        }
      ].concat(meta)}
    />
  )
}

export default HtmlHead
